import bugsnagClient from '@/lib/bugsnag'
import AppStore from '@/stores/AppStore'
import errorMessageHandler from '@/utils/errorMessageHandler'

export interface CheckoutFormState {
  isLoading: boolean
  errorMessage: string | undefined
}

export default function handleError(appStore: AppStore, checkoutFormState: CheckoutFormState, error) {
  // Reporting
  if (!isNetworkError(error)) {
    console.error(error)
    bugsnagClient.notify(error)
  }

  // Client
  checkoutFormState.errorMessage = errorMessageHandler(error.response?.data || {})?.join()
  checkoutFormState.isLoading = false
  appStore.state.error = null
  appStore.state.paymentMethodId = null
  appStore.state.paymentIntentId = null
}

function isNetworkError(error: { type?: string; message?: string }) {
  return error.type === 'fetch_error' || error.message === 'Network Error'
}
