/* User Settings - feel free to change */
const DEFAULT_LANGUAGE = 'fr'
const OTHER_LANGUAGES = ['en', 'es', 'de', 'it', 'pt', 'nl', 'ca', 'no', 'sv', 'da']
const DEFAULT_NAMESPACE = 'translation'
const LOCALE_PATH = 'locales'
const LOCALE_SUBPATHS = false

/* Core Settings - only change if you understand what you're changing */
const config = {
  debug: false,
  allLanguages: OTHER_LANGUAGES.concat([DEFAULT_LANGUAGE]),
  otherLanguages: OTHER_LANGUAGES,
  defaultLanguage: DEFAULT_LANGUAGE,
  fallbackLng: ['fr'],
  fallbackNS: DEFAULT_NAMESPACE,
  load: 'languageOnly',
  localesPath: `./${LOCALE_PATH}/`,
  localeSubpaths: LOCALE_SUBPATHS,
  ns: [DEFAULT_NAMESPACE, 'validation'],
  defaultNS: DEFAULT_NAMESPACE,
  interpolation: {
    escapeValue: false,
    formatSeparator: ','
  }
}

module.exports = config
