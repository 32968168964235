import '@/main.css'
import '@zenchef/react-intl-tel-input/dist/main.css'
import 'react-day-picker/lib/style.css'
import '@zenchef/icomoon/aromaticons.css'
import '@/components/ui/CustomTelInput/CustomTelInput.css'
import '@zenchef/ui/src/components/datepicker/Datepicker.css'
import 'overlayscrollbars/overlayscrollbars.css'

import Bugsnag from '@bugsnag/js'
import { detectDevice } from '@zenchef/ui'
import { format } from 'date-fns'
import { i18n } from 'i18next'
import { sanitize } from 'isomorphic-dompurify'
import { configure } from 'mobx'
import { AppProps } from 'next/app'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useReducer, useState } from 'react'
import { I18nextProvider, useSSR } from 'react-i18next'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import url from 'url'

import { CookieIframe } from '@/components'
import i18next from '@/i18n'
import config from '@/i18next-options'
import { initStores } from '@/initStores'
import { initDataLayersData, sendRouterPageView } from '@/lib/GoogleAnalytics'
import AppStore from '@/stores/AppStore'
import { NextPageWithLayout } from '@/types/types'
import getSnapshot from '@/utils/getSnapshot'
import { getHumanCivilityFromShort } from '@/utils/helpers'
import { SDKEventsContext, SdkEventStateReducer } from '@/utils/hooks/useSdkEvents'
import { initializeAnalytics } from '@/utils/initializeAnalytics'
import normalizeLang from '@/utils/normalizeLang'
import StoresContext, { Stores } from '@/utils/StoresContext'
import zenchefTheme from '@/utils/theme'

import ErrorComponent from './_error'

interface InitialProps {
  pageProps
  initialState: { appState: AppStore['state'] }
  initialLanguage: string
  initialI18nStore: Record<string, string>
  isServer: boolean
  query: NextParsedUrlQuery
  theme: DefaultTheme
}

type AppPropsWithLayout = AppProps &
  InitialProps & {
    Component: NextPageWithLayout
  }

configure({
  enforceActions: 'never'
})

if (typeof window === 'undefined') {
  React.useLayoutEffect = React.useEffect
}

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

const i18nextContainer: { i18next?: i18n } = {}

const getNewPathname = async (pathname, appStore, query) => {
  if (
    ![
      '/results',
      '/offers',
      '/shop',
      '/privatisation',
      '/shop-form',
      '/shop-payment',
      '/shop_thank_you',
      '/form',
      '/thank_you',
      '/thank_you_privatisation',
      '/credit_card_imprint',
      '/prepayment',
      '/suggestions',
      '/incoming-call'
    ].includes(pathname)
  ) {
    return pathname
  }

  if (
    !appStore.state.acl.includes('voucher') &&
    ['/shop', '/shop-form', '/shop-payment', '/shop_thank_you'].includes(pathname)
  ) {
    return '/unavailable-shop'
  }
  if (!appStore.state.acl.includes('incoming_caller_notify') && ['/incoming-call'].includes(pathname)) {
    return '/unavailable'
  }
  if (!appStore.state.restaurantId || (appStore.state.isDisabled && !query?.isPreview)) {
    return '/unavailable'
  }

  if (pathname === '/') {
    return '/results'
  }

  if (
    ['/offers', '/form', '/thank_you', '/thank_you_privatisation', '/credit_card_imprint', '/prepayment'].includes(
      pathname
    )
  ) {
    if (query && query['force-prepayment'] && query.token) {
      return '/prepayment'
    }

    if (query && query.setup_intent && query.setup_intent_client_secret) {
      return '/credit_card_imprint'
    }

    const { pax, day, slot } = appStore.state.wish
    if (!pax || !day || (!slot && pathname !== '/thank_you_privatisation')) {
      // no slot in privatization.
      return '/results'
    }
  }

  if (['/suggestions'].includes(pathname)) {
    const { pax, day } = appStore.state.wish
    const { suggestedAppStores } = appStore.state
    if (!pax || !day || Object.keys(suggestedAppStores).length === 0) {
      // no slot in privatization.
      return '/results'
    }
  }

  if (
    (['/shop-form', '/shop-payment', '/shop_thank_you'].includes(pathname) &&
      !appStore.state.selectedProducts.length) ||
    (pathname === '/shop_thank_you' && !appStore.state.order.id)
  ) {
    return '/shop'
  }

  return pathname
}

const redirect = (res, redirectUrl) => {
  if (res) {
    console.log(redirectUrl)
    res.writeHead(302, { Location: redirectUrl })
    res.end()
  } else {
    Router.push(redirectUrl)
  }
}

const redirectRequest = async ({ req, res, pathname, query }, stores) => {
  const newPathname = await getNewPathname(pathname, stores.appStore, query)
  const { lang, ...restQuery } = query
  let newLang: { lang?: AvailableLanguages } = { lang: 'en' }
  if (lang !== undefined) {
    if (stores.appStore.state.language_availabilities.length) {
      if (stores.appStore.state.language_availabilities.includes(lang)) {
        newLang = { lang }
      }
    } else {
      if (config.allLanguages.includes(lang)) {
        newLang = { lang }
      }
    }
  } else {
    newLang = {}
  }
  const newQuery = {
    ...restQuery,
    // ...(lang === undefined ? {} : config.allLanguages.includes(lang) ? { lang } : { lang: 'en' })
    ...newLang
  }
  const isServer = !!req
  if (isServer) {
    if (pathname !== newPathname || query.lang !== newQuery.lang) {
      console.info(`redirecting from ${pathname} to ${newPathname} server side`)
      if (res && !res.finished) {
        redirect(
          res,
          url.format({
            pathname: newPathname,
            query: newQuery
          })
        )
      }
    }
  } else {
    if (pathname !== newPathname || query.lang !== newQuery.lang) {
      // console.info(`redirecting from ${pathname} to ${newPathname} client side`)
      Router.push({
        pathname: newPathname,
        query: newQuery
      })
    }
  }
}

const MyApp = ({
  Component,
  pageProps,
  theme,
  isServer,
  query,
  initialState,
  initialLanguage,
  initialI18nStore
}: AppPropsWithLayout) => {
  let [stores, setStores] = useState<Stores>()
  let [i18nextInstance, setI18nextInstance] = useState<i18n>()
  const [sdkSentValues, dispatchSdkEvent] = useReducer(SdkEventStateReducer, {})

  const router = useRouter()
  const { pathname } = router

  if (!stores) {
    stores = initStores(isServer, query, initialState)
  }
  if (!i18nextInstance) {
    if (process.browser) {
      i18nextInstance = i18next
    } else {
      i18nextInstance = i18nextContainer.i18next
    }
  }
  const { appStore } = stores
  const populateDataLayer = (appStore) => {
    initDataLayersData({
      restaurant_id: appStore.state.restaurantId,
      partner_id: appStore.state.partner_id,
      pax: appStore.state.wish.pax,
      date: appStore.state.wish.day,
      time: appStore.state.wish.slot?.name
    })
  }

  useEffect(() => {
    setStores(stores)
    setI18nextInstance(i18nextInstance)
  }, [])

  useEffect(() => {
    try {
      if (!appStore.state.initialized) {
        appStore.init()
      }
    } catch (e) {
      console.error(e)
    }
    // @ts-ignore we need to type our custom config of i18n https://react.i18next.com/latest/typescript
    i18next.defaultLanguage = i18next.language
    i18next.on('languageChanged', (lng) => {
      console.log('language changed', lng)
      appStore.state.language = lng
    })

    appStore.state.type = appStore.state.type || detectDevice(window.navigator.userAgent)
    initializeAnalytics(appStore)
    populateDataLayer(appStore)

    const completePath = window.location.pathname
  }, [])

  // analytics pageview events
  useEffect(() => {
    const handleRouteChange = (url) => {
      sendRouterPageView(url)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])

  const getLayout = Component.getLayout || ((page) => page)

  if (!ErrorBoundary) {
    throw new Error('ErrorBoundary is not initialized')
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent}>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' />
        {getNumberlyTag()}
        {getDataLayerTag()}
        {getFacebookPixelTag()}
        <title>{(initialState && initialState.appState && initialState.appState.name) || 'Réservations'}</title>
      </Head>
      {/* @ts-ignore : need to upgrade i18next - https://github.com/i18next/react-i18next/issues/1477  */}
      <I18nextProvider i18n={i18next}>
        <InitSSR initialI18nStore={initialI18nStore} initialLanguage={initialLanguage} />
        <StoresContext.Provider value={stores}>
          <SDKEventsContext.Provider value={{ ...sdkSentValues, dispatch: dispatchSdkEvent }}>
            <ThemeProvider theme={theme || zenchefTheme}>
              <>{getLayout(<Component {...pageProps} />, { stores, router, t: i18next.t })}</>
            </ThemeProvider>
          </SDKEventsContext.Provider>
        </StoresContext.Provider>
      </I18nextProvider>
      {query && query.cmp && <CookieIframe query={query} />}
    </ErrorBoundary>
  )

  function getNumberlyTag() {
    return (
      appStore.state.analytics_tag === 'numberly' &&
      pathname &&
      pathname.indexOf('/thank_you_privatisation') === -1 &&
      pathname.indexOf('/thank_you') !== -1 && (
        <script
          defer
          dangerouslySetInnerHTML={{
            __html: `(function (d,e,f,g,a,b,c) {
              d._troq || (a=d._troq = function () {
                a.callTro ? a.callTro.apply(a, arguments) : a.queue.push(arguments)
              },
              a.push = a, a.queue = [],
              a.version = '1.0',
              b = e.createElement(f),
              b.async = !0,
              b.src = g,
              c = e.getElementsByTagName(f)[0],
              c.parentNode.insertBefore(b,c))
            })(window, document, 'script', '//mmtro.com/tro.js');
            _troq('session_ttl', 1800);
            _troq('init', '6551662-573e1959ca2b1c98d7d89cb160b04c44');
            _troq('tag', {
              rtguidrestaurant: '${sanitize(appStore.state.restaurantId ?? '')}',
              sha256:'${sanitize(appStore.state.sha256)}',
              rtgnbguests: '${appStore.state.wish.pax}',
              rgtdate: '${sanitize(format(appStore.state.wish.day, 'DDMMYYYY'))}',
              rtghour: '${sanitize(appStore.state.wish.slot?.replace(':', '') ?? '')}',
              rtgcivilite: '${getHumanCivilityFromShort(appStore.state.formData.civility)}',
              rtgname: '${sanitize(appStore.state.formData.lastname)}',
              rtgfirstname: '${sanitize(appStore.state.formData.firstname)}',
              rtgphonenumber: '${sanitize(appStore.state.formData.phone)}',
              rtgidform: 'reservation',
              rtgpg: 'completedform',
              rtgoptin: ${
                appStore.state.formData.optins &&
                appStore.state.formData.optins.find((optin) => optin.type === 'market_mail')
                  ? appStore.state.formData.optins.find((optin) => optin.type === 'market_mail')?.value
                  : 0
              },
              rtgoptinsms: ${
                appStore.state.formData.optins &&
                appStore.state.formData.optins.find((optin) => optin.type === 'market_sms')
                  ? appStore.state.formData.optins.find((optin) => optin.type === 'market_sms')?.value
                  : 0
              },
            })`
          }}
        />
      )
    )
  }
  function getDataLayerTag(): ReactNode {
    // A new GA4 events are pushed to the dataLayer in the thank_you page
    return appStore.state.tagManager &&
      pathname &&
      pathname.indexOf('/thank_you_privatisation') === -1 &&
      pathname.indexOf('/thank_you') !== -1 ? (
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window['dataLayerClient'].push({
              event: 'Réservation',
              rtguidrestaurant: '${appStore.state.restaurantId}',
              sha256:'${sanitize(appStore.state.sha256)}',
              rtgnbguests: '${appStore.state.wish.pax}',
              rgtdate: '${sanitize(format(appStore.state.wish.day, 'DDMMYYYY'))}',
              rtghour: '${sanitize(appStore.state.wish.slot?.replace(':', '') ?? '')}',
              rtgcivilite: '${getHumanCivilityFromShort(appStore.state.formData.civility)}',
              rtgname: '${sanitize(appStore.state.formData.lastname)}',
              rtgfirstname: '${sanitize(appStore.state.formData.firstname)}',
              rtgphonenumber: '${sanitize(appStore.state.formData.phone)}',
              rtgidform: 'reservation',
              rtgpg: 'completedform',
              rtgpartner: '${sanitize(appStore.state.partner_id ?? '')}',
              rtgoptin: ${
                appStore.state.formData.optins &&
                appStore.state.formData.optins.find((optin) => optin.type === 'market_mail')
                  ? appStore.state.formData.optins.find((optin) => optin.type === 'market_mail')?.value
                  : 0
              },
              rtgoptinsms: ${
                appStore.state.formData.optins &&
                appStore.state.formData.optins.find((optin) => optin.type === 'market_sms')
                  ? appStore.state.formData.optins.find((optin) => optin.type === 'market_sms')?.value
                  : 0
              }
            })`
        }}
      />
    ) : null
  }
  function getFacebookPixelTag() {
    return pathname && appStore.state.facebookPixel && pathname.indexOf('/thank_you') !== -1 ? (
      <script
        defer
        dangerouslySetInnerHTML={{
          __html: `
              fbq('trackCustom','Reservation');
              fbq('track', 'Schedule');
            `
        }}
      />
    ) : null
  }
}

MyApp.getInitialProps = async ({ Component, ctx }): Promise<InitialProps | unknown> => {
  const { req, res, query } = ctx
  const isServer = !!req

  let pageProps: Record<string, unknown> = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  pageProps.namespacesRequired = pageProps.namespacesRequired || ['translation']

  if (['/welcome', '/_error'].includes(ctx.pathname)) {
    return { pageProps }
  }

  const stores = initStores(isServer, query, null)

  if (isServer) {
    try {
      await stores.appStore.initServerSide(ctx)
    } catch (e) {
      console.log(e.message)
      const url = require('url')
      console.log(`there was an error initalizing appStore serverside for : ${stores.appStore.state.restaurantId}`)
      if (ctx.pathname !== '/unavailable' && res && !res.finished) {
        redirect(
          res,
          url.format({
            pathname: '/unavailable',
            query
          })
        )
        res.finished = true
      }
    }
  }

  redirectRequest(ctx, stores)

  let initialLanguage = 'fr'
  if (query && query.lang) {
    if (stores.appStore.state.language_availabilities.length) {
      if (stores.appStore.state.language_availabilities.includes(query.lang)) {
        initialLanguage = query.lang
      } else {
        initialLanguage = 'en'
      }
    } else {
      initialLanguage = normalizeLang(query.lang)
    }
  } else {
    if (stores.appStore.state.language) {
      initialLanguage = stores.appStore.state.language
    }
  }

  const i18nextInstance = i18next
  i18nextContainer.i18next = i18nextInstance

  if (isServer && i18nextInstance.language !== initialLanguage) {
    if (!i18nextInstance.hasResourceBundle(initialLanguage, 'translation')) {
      const [newtranslation, newValidationTranslation] = await Promise.all([
        import(`../../public/locales/${initialLanguage}/translation.json`),
        import(`../../public/locales/${initialLanguage}/validation.json`)
      ])
      i18next.addResourceBundle(initialLanguage, 'translation', newtranslation)
      i18next.addResourceBundle(initialLanguage, 'validation', newValidationTranslation)
    }
    i18nextInstance.changeLanguage(initialLanguage)
  }
  const theme = stores.appStore.state.theme

  return {
    pageProps,
    initialState: getSnapshot(stores),
    initialLanguage: i18next.language,
    initialI18nStore: { [i18next.language]: i18next.store.data[i18next.language] },
    isServer,
    query,
    theme
  }
}

export function InitSSR({ initialI18nStore, initialLanguage }) {
  useSSR(initialI18nStore, initialLanguage)
  return null
}

export default MyApp
